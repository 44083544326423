@import "@/assets/scss/var.scss";
.checkout {
    background: #f7f8fa;
    .flexrow {
        display: flex;
        align-items: center;
    }
    .checkPt-tips {
        /deep/ .el-alert__description {
            font-size: 14px;
            font-weight: bold;
            .is-pointer {
                cursor: pointer;
            }
        }
    }
    .row {
        background: #fff;
        padding: 20px;
        margin-bottom: 10px;
        &-label {
            min-width: 250px;
        }
        .opendisc {
            margin-left: 20px;
            color: $color-primary;
            cursor: pointer;
        }
        &-form {
            flex-flow: column;
            width: 60%;
        }
    }
    &-foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .coupon {
        display: flex;
        flex-flow: column;
        &-item {
            display: flex;
        }
        .discount {
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #f1f1f1;
        }
    }
    .ship-methods {
        /deep/.sw-radio-group {
            .el-radio {
                width: 210px;
            }
        }
        .frc {
            margin-bottom: 10px;
            font-size: 14px;
        }
    }
    .address {
        margin: 20px 0;
        .el-radio-group {
            display: flex;
            flex-flow: column;
            .el-radio {
                display: flex;
            }
            .el-radio + .el-radio {
                margin-top: 20px;
            }
            .el-radio__label {
                width: 100%;
                display: inline-block;
                font-size: 16px;
            }
        }
        &-box {
            > span + span {
                margin-left: 15px;
            }
            .handel {
                color: $color-primary;
            }
        }
        &-btn {
            margin-top: 20px;
        }
    }
    .invoice {
        &-form {
            display: flex;
            > div {
                width: 340px;
            }
            > div:first-child {
                margin-right: 20px;
            }
        }
    }
    .coupon-item .el-input {
      margin-top: -10px;
    }
}
